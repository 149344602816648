<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      Öğretmenevi Yönetim Sistemi © 3K-CRM Mimarisi üzerine dizayn edilmiştir.
      Tüm Hakları Saklıdır.
    </span>
    <span class="float-md-right d-none d-md-block"
      ><a href="https://www.arvas.com.tr/"> Arvas Bilgi Teknolojileri </a> &amp;
      Version:3.0-Beta
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>

<style scoped>
p,
a {
  color: white !important;
}
</style>
