import store from '@/store'

export default [
  {
    title: 'Kurum Listesi',
    route: '/',
    icon: 'HomeIcon',
  },
  {
    title: 'Servis İşlemleri',
    icon: 'ServerIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Kurum Kayıt',
        route: 'kurumKayit',
        role: 1,
      },
      {
        title: 'API Dokümantasyon',
        route: 'dokumantasyon',
      },
    ],
  },
]
