<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="text-white user-name font-weight-bolder mb-0">
          {{ getFullName }}
        </p>
        <span class="text-white user-status">{{ role }}</span>
      </div>
      <div>
        <b-avatar
            size="40"
            src="require('https://demopanel.oys.web.tr/app-assets/images/portrait/small/avatar.png')"
            variant="ligth"
            badge
            class="badge-minimal"
            badge-variant="success"
        >
          <feather-icon icon="UserIcon" size="22"/>
        </b-avatar>
      </div>
    </template>
    <b-dropdown-item
        link-class="d-flex align-items-center"
        v-b-modal.sifre-guncelle
    >
      <feather-icon size="16" icon="EditIcon" class="mr-50"/>
      <span>Şifre Güncelle</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="PowerIcon" class="mr-50"/>
      <span>Çıkış Yap</span>
    </b-dropdown-item>
    <b-modal
        id="sifre-guncelle"
        cancel-variant="secondary"
        ok-only
        hide-footer
        centered
        size="xs"
        title="Şifre Güncelle"
        @hide="sifreUpdateHide"
    >
      <b-form-group label="Yeni Şifreniz">
        <b-form-input
            id="basicInput"
            v-model="pwd"
            @keyup.enter="sifreUpdate"
            autocomplete="off"
        />
      </b-form-group>
      <hr/>
      <b-row style="justify-content: space-evenly">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            @click="sifreUpdate"
        >
          Güncelle!
        </b-button>
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="danger"
            @click="$bvModal.hide('sifre-guncelle')"
        >
          İptal
        </b-button>
      </b-row>
    </b-modal>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormGroup,
  BFormInput,
  BRow,
  BButton,
  BCol,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      pwd: '',
      role: this.$store.getters.getRole == 1 ? 'Yönetici' : 'Personel',
      avatarText,
    }
  },
  computed: {
    getFullName() {
      return localStorage.getItem('raporFullName')
    },
    getRole() {
      return localStorage.getItem('raporUserName')
    },
  },
  methods: {
    sifreUpdate() {
      this.$store
          .dispatch('sifreUpdate', {
            pwd: this.pwd,
          })
          .then((res, position = 'bottom-right') => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position }
              )
            }
            this.$bvModal.hide('sifre-guncelle')
          })
          .catch((err) => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    sifreUpdateHide() {
      this.pwd = ''
    },
    logout() {
      store.commit('removeToken')
      store.commit('removeUserData')
      store.state.isAuth = false
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'login' })
    },
  },
  directives: {
    Ripple,
  },
}
</script>
